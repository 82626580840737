<template>
  <div>
    <el-container>
      <el-header>
        <div class="div">
          <div style="margin-top: 8px">
            <img src="../assets/title.png" height="34"/>
          </div>
          <el-menu mode="horizontal" router text-color="#606266"
                   :default-active="'/'+this.$route.path.split('/')[1]">
            <el-menu-item index="/book">
              <i class="el-icon-search el-icon"/>
              <span>书目检索</span>
            </el-menu-item>
            <!---->
            <el-menu-item index="/cart">
              <i class="el-icon-shopping-cart-2 el-icon"/>
              <span>购物车</span>
            </el-menu-item>
            <!---->
            <el-menu-item index="/order">
              <i class="el-icon-document-add el-icon"/>
              <span>订货单</span>
            </el-menu-item>
            <!---->
            <el-menu-item index="/back">
              <i class="el-icon-document-remove el-icon"/>
              <span>退货单</span>
            </el-menu-item>
            <!---->
            <el-submenu index="0" :show-timeout=200 :hide-timeout=200>
              <template slot="title">
                <i class="el-icon-data-line el-icon"/>
                <span style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 15px">统计报表</span>
              </template>
              <el-menu-item index="/orderget" class="menuitem">代提统计</el-menu-item>
              <!--              <el-divider/>-->
              <!--              <el-menu-item index="/backget" class="menuitem">代退统计</el-menu-item>-->
              <el-divider/>
              <el-menu-item index="/payget" class="menuitem">代付统计</el-menu-item>
            </el-submenu>
            <!---->
            <el-menu-item index="/seller">
              <i class="el-icon-user el-icon"/>
              <span>供货商</span>
            </el-menu-item>
          </el-menu>
          <div style="width: 100%;display: flex;justify-content: flex-end;align-items: center;">
            <el-link :underline="false" class="link" @click="getUser" style="margin-right: 10px">
              <i class="el-icon-s-custom icon"/>{{name}}
            </el-link>
            <el-link :underline="false" class="link" @click="logout" style="color:#EB6100">退出
            </el-link>
          </div>
        </div>
      </el-header>
      <el-main style="overflow: hidden">
        <router-view></router-view>
      </el-main>
      <el-footer>
        <el-link class="link1" href="https://beian.miit.gov.cn/"
                 target="_blank" :underline="false">
          冀ICP备2024052321号-2
        </el-link>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
  export default {
    created () {
      this.name = window.localStorage.getItem('name')
    },
    data () {
      return {
        name: ''
      }
    },
    methods: {
      getUser () {
        this.$router.push('/user')
      },
      logout () {
        window.localStorage.clear()
        window.sessionStorage.clear()
        this.$router.push('/login')
      }
    }
  }
</script>

<style lang="less" scoped>
  .el-divider--horizontal {
    margin: 1px 10px;
    height: 1px;
    width: 90px;
  }

  .el-divider {
    background-color: #0000000c
  }

  .el-header {
    z-index: 1;
    position: fixed;
    width: 100%;
    height: 50px !important;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
    background-color: #FFF2E2;
    padding: 0;
  }

  .el-main {
    z-index: 0;
    width: 1000px;
    margin: 50px auto;
    padding: 0;
  }

  .el-footer {
    width: 100%;
    height: 100px !important;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center
  }

  .div {
    width: 1000px;
    height: 50px;
    margin: 0 auto;
    display: flex;
    justify-content: left;
  }

  .el-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 0 20px !important;
    background-color: #FFF2E2;
  }

  .el-menu-item {
    display: flex;
    align-items: center;
    height: 15px !important;
    padding: 0;
    margin: 0 15px !important;
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 15px;
  }

  /deep/ .el-menu--horizontal > .el-submenu .el-submenu__title {
    display: flex;
    align-items: center;
    height: 15px !important;
    border-bottom: none !important;
    margin: 0 15px !important;
    padding: 0 !important;
    line-height: 15px;
    color: #606266 !important;
    background-color: #FFF2E2 !important;
  }

  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }

  .el-menu-item.is-active {
    border-bottom: none !important;
    color: #606266 !important;
    background-color: #FFF2E2 !important;
  }

  .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
    border-bottom: none !important;
    color: #606266 !important;
    background-color: #FFF2E2 !important;
  }

  .el-menu--horizontal > .el-menu-item {
    border-bottom: none;
    line-height: 15px;
  }

  .el-menu-item:hover {
    color: #00479D !important;
    background-color: #FFF2E2 !important;
  }

  /deep/ .el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
    margin-left: 3px !important;
  }

  .span {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
    color: #606266;
  }

  .link {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
    color: black;
  }

  .link1 {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
  }

  .icon {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
    color: #00479D;
    margin-right: 3px;
  }

  .menuitem {
    display: flex;
    align-items: center;
    padding: 0 !important;
    height: 15px !important;
    width: 30px !important;
    margin: 15px 25px !important;
    background-color: #FFF2E2 !important;
  }

  .el-icon {
    font-size: 16px;
    color: #EB6100;
    margin-right: 0;
  }

</style>
